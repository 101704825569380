
     const data = [
        {id: 0, tuningName: "4-string standard (E A D G)", tuningTitle: "E, A, D, G", description: "4-string standard tuning: E, A, D, G", numStrings: 4,
            tuning: [
                {string: 0, name: "E", midi: 40, pitch: "E1", aria:"E"},
                {string: 1, name: "A", midi: 45, pitch: "A1", aria:"A"},
                {string: 2, name: "D", midi: 50, pitch: "D2", aria:"D"},
                {string: 3, name: "G", midi: 55, pitch: "G2", aria:"G"}
            ]
        },
        {id: 1, tuningName: "5-string standard (B E A D G)", tuningTitle: "B, E, A, D, G", description: "5-string standard tuning: B, E, A, D, G", numStrings: 5,
            tuning: [
                {string: 0, name: "B", midi: 35, pitch: "B0", aria:"B"},
                {string: 1, name: "E", midi: 40, pitch: "E1", aria:"E"},
                {string: 2, name: "A", midi: 45, pitch: "A1", aria:"A"},
                {string: 3, name: "D", midi: 50, pitch: "D2", aria:"D"},
                {string: 4, name: "G", midi: 55, pitch: "G2", aria:"G"}
            ]
        },
        {id: 2, tuningName: "6-string standard (B E A D G C)", tuningTitle: "B, E, A, D, G, C", description: "6-string standard tuning: B, E, A, D, G, C", numStrings: 6,
            tuning: [
                {string: 0, name: "B", midi: 35, pitch: "B0", aria:"B"},
                {string: 1, name: "E", midi: 40, pitch: "E1", aria:"E"},
                {string: 2, name: "A", midi: 45, pitch: "A1", aria:"A"},
                {string: 3, name: "D", midi: 50, pitch: "D2", aria:"D"},
                {string: 4, name: "G", midi: 55, pitch: "G2", aria:"G"},
                {string: 5, name: "C", midi: 60, pitch: "C3", aria:"C"}
            ]
        },
         {id: 3, tuningName: "4-string drop-D (D A D G)", tuningTitle: "D, A, D, G", description: "Drop-D tuning: D, A, D, G", numStrings: 4,
             tuning: [
                 {string: 0, name: "D", midi: 38, pitch: "D1", aria:"Low D"},
                 {string: 1, name: "A", midi: 45, pitch: "A1", aria:"A"},
                 {string: 2, name: "D", midi: 50, pitch: "D2", aria:"D"},
                 {string: 3, name: "G", midi: 55, pitch: "G2", aria:"G"}
             ]
         },
         {id: 4, tuningName: "4-string half-step down (E\u266d A\u266d D\u266d G\u266d)", tuningTitle: "E\u266d A\u266d D\u266d G\u266d", description: "Half-step down tuning: E flat, A flat, D flat, G-flat", numStrings: 4,
             tuning: [
                 {string: 0, name: "E\u266d", midi: 39, pitch: "Eb1", aria:"E, flat"},
                 {string: 1, name: "A\u266d", midi: 44, pitch: "Ab1", aria:"A, flat"},
                 {string: 2, name: "D\u266d", midi: 49, pitch: "Db2", aria:"D, flat"},
                 {string: 3, name: "G\u266d", midi: 54, pitch: "Gb2", aria:"G, flat"}
             ]
         },
         {id: 5, tuningName: "4-string whole-step down (D G C F)", tuningTitle: "D G C F", description: "Whole-step down tuning: D, G, C, F", numStrings: 4,
             tuning: [
                 {string: 0, name: "D", midi: 38, pitch: "D1", aria:"D"},
                 {string: 1, name: "G", midi: 43, pitch: "G1", aria:"G"},
                 {string: 2, name: "C", midi: 48, pitch: "C2", aria:"C"},
                 {string: 3, name: "F", midi: 53, pitch: "F2", aria:"F"}
             ]
         },
         {id: 6, tuningName: "4-string 'Drop C' (C A D G)", tuningTitle: "C A D G", description: "Drop C: C, A, D, G", numStrings: 4,
             tuning: [
                 {string: 0, name: "C", midi: 36, pitch: "C1", aria:"Low C"},
                 {string: 1, name: "A", midi: 45, pitch: "A1", aria:"A"},
                 {string: 2, name: "D", midi: 50, pitch: "D2", aria:"D"},
                 {string: 3, name: "G", midi: 55, pitch: "G2", aria:"G"}
             ]
         },
         {id: 7, tuningName: "4-string 'Low B' (B E A D)", tuningTitle: "B, E, A, D", description: "4-string Low B tuning: B, E, A, D", numStrings: 4,
             tuning: [
                 {string: 0, name: "B", midi: 35, pitch: "B0", aria:"B"},
                 {string: 1, name: "E", midi: 40, pitch: "E1", aria:"E"},
                 {string: 2, name: "A", midi: 45, pitch: "A1", aria:"A"},
                 {string: 3, name: "D", midi: 50, pitch: "D2", aria:"D"}
             ]
         },
         {id: 8, tuningName: "5-string half-step down (B\u266d E\u266d A\u266d D\u266d G\u266d)", tuningTitle: "B\u266d E\u266d A\u266d D\u266d G\u266d", description: "Half-step down tuning: B flat, E flat, A flat, D flat, G-flat", numStrings: 5,
             tuning: [
                 {string: 0, name: "B\u266d", midi: 34, pitch: "Bb0", aria:"B, flat"},
                 {string: 1, name: "E\u266d", midi: 39, pitch: "Eb1", aria:"E, flat"},
                 {string: 2, name: "A\u266d", midi: 44, pitch: "Ab1", aria:"A, flat"},
                 {string: 3, name: "D\u266d", midi: 49, pitch: "Db2", aria:"D, flat"},
                 {string: 4, name: "G\u266d", midi: 54, pitch: "Gb2", aria:"G, flat"}
             ]
         },
         {id: 9, tuningName: "5-string whole-step down (A D G C F)", tuningTitle: "A D G C F", description: "Whole-step down tuning: A, D, G, C, F", numStrings: 5,
             tuning: [
                 {string: 0, name: "A", midi: 34, pitch: "A0", aria:"A"},
                 {string: 1, name: "D", midi: 39, pitch: "D1", aria:"D"},
                 {string: 2, name: "G", midi: 44, pitch: "G1", aria:"G"},
                 {string: 3, name: "C", midi: 49, pitch: "C2", aria:"C"},
                 {string: 4, name: "F", midi: 54, pitch: "F2", aria:"F"}
             ]
         },
         {id: 10, tuningName: "High 5 (E A D G C)", tuningTitle: "E, A, D, G, C", description: "5-string high C: E, A, D, G, C", numStrings: 5,
             tuning: [
                 {string: 0, name: "E", midi: 40, pitch: "E1", aria:"E"},
                 {string: 1, name: "A", midi: 45, pitch: "A1", aria:"A"},
                 {string: 2, name: "D", midi: 50, pitch: "D2", aria:"D"},
                 {string: 3, name: "G", midi: 55, pitch: "G2", aria:"G"},
                 {string: 4, name: "C", midi: 60, pitch: "C3", aria:"C"}
             ]
         },
         {id: 11, tuningName: "High 5 half-step down (E\u266d A\u266d D\u266d G\u266d B)", tuningTitle: "E\u266d A\u266d D\u266d G\u266d B", description: "Half-step down tuning: E flat, A flat, D flat, G-flat, B", numStrings: 5,
             tuning: [
                 {string: 0, name: "E\u266d", midi: 39, pitch: "Eb1", aria:"E, flat"},
                 {string: 1, name: "A\u266d", midi: 44, pitch: "Ab1", aria:"A, flat"},
                 {string: 2, name: "D\u266d", midi: 49, pitch: "Db2", aria:"D, flat"},
                 {string: 3, name: "G\u266d", midi: 54, pitch: "Gb2", aria:"G, flat"},
                 {string: 4, name: "B", midi: 59, pitch: "B2", aria:"B"}
             ]
         },
         {id: 12, tuningName: "High 5 whole-step down (D G C F B\u266d)", tuningTitle: "D, G, C, F, B\u266d", description: "5-string high string whole-step down: D, G, C, F, B flat", numStrings: 5,
             tuning: [
                 {string: 0, name: "D", midi: 38, pitch: "D1", aria:"D"},
                 {string: 1, name: "G", midi: 43, pitch: "G1", aria:"G"},
                 {string: 2, name: "C", midi: 48, pitch: "C2", aria:"C"},
                 {string: 3, name: "F", midi: 53, pitch: "F2", aria:"F"},
                 {string: 4, name: "B\u266d", midi: 58, pitch: "Bb2", aria:"B flat"}
             ]
         },
         {id: 13, tuningName: "6-string half-step down (B\u266d E\u266d A\u266d D\u266d G\u266d B)", tuningTitle: "B\u266d E\u266d A\u266d D\u266d G\u266d B", description: "Half-step down tuning: B flat, E flat, A flat, D flat, G-flat, B", numStrings: 6,
             tuning: [
                 {string: 0, name: "B\u266d", midi: 34, pitch: "Bb0", aria:"B, flat"},
                 {string: 1, name: "E\u266d", midi: 39, pitch: "Eb1", aria:"E, flat"},
                 {string: 2, name: "A\u266d", midi: 44, pitch: "Ab1", aria:"A, flat"},
                 {string: 3, name: "D\u266d", midi: 49, pitch: "Db2", aria:"D, flat"},
                 {string: 4, name: "G\u266d", midi: 54, pitch: "Gb2", aria:"G, flat"},
                 {string: 5, name: "B", midi: 59, pitch: "B2", aria:"B"}
             ]
         },
         {id: 14, tuningName: "6-string whole-step down (A D G C F B\u266d)", tuningTitle: "A D G C F B\u266d", description: "Whole-step down tuning: A, D, G, C, F, B flat", numStrings: 6,
             tuning: [
                 {string: 0, name: "A", midi: 34, pitch: "A0", aria:"A"},
                 {string: 1, name: "D", midi: 39, pitch: "D1", aria:"D"},
                 {string: 2, name: "G", midi: 44, pitch: "G1", aria:"G"},
                 {string: 3, name: "C", midi: 49, pitch: "C2", aria:"C"},
                 {string: 4, name: "F", midi: 54, pitch: "F2", aria:"F"},
                 {string: 5, name: "B\u266d", midi: 58, pitch: "Bb2", aria:"B flat"}
             ]
         },
]
     export default data