import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {Helmet} from "react-helmet";

export default function TOS() {

    return (
        <>
            <Helmet>
                <title>Terms of Service</title>
            </Helmet>
            <Grid container justify="center">
                <Grid item xs={12} sm={8} md={6} spacing={8}>
                    <Typography variant={"h1"} align={"center"} gutterBottom>Terms of Service</Typography>
                    <Typography variant="body2">Effective Date: June 20, 2005</Typography>
                    <Typography variant="body2">Welcome to the TuneMyBass.com website (the &ldquo;Website&rdquo;). The
                        following Terms of Service (&ldquo;TOS&rdquo;) contain the terms and conditions that govern your
                        use of the Website and the Service (as defined below). The TOS describe your rights and
                        responsibilities and what you can expect from the Service. If you do not read, understand, and
                        agree to all of the TOS, you may not use the Website.<br/>
                        <br/>
                        TuneMyBass.com reserves the right to add to, delete or change these TOS. As such, you
                        should check these TOS from time to time for such changes.&nbsp;</Typography>
                    <Typography variant="h3">1. SERVICE</Typography>
                    <Typography variant="body2">TuneMyBass.com provides Internet-based services (&ldquo;Service&rdquo;)
                        through the Website. Such services include a bass tuning reference and bass-related articles and
                        tutorials.</Typography>
                    <Typography variant="h3">2. ELIGIBILITY</Typography>
                    <Typography variant="body2">TuneMyBass.com will only knowingly provide Service to parties that can
                        lawfully enter into and form contracts under applicable law. If you are under the age of 18, but
                        at least 13 years of age, you may use the Service only under the supervision of a parent or
                        legal guardian who agrees to be bound by these TOS. The Service is not intended for, and may not
                        be used by, children under the age of 13.</Typography>
                    <Typography variant="h3">3. COMPLIANCE WITH TOS AND APPLICABLE LAW</Typography>
                    <Typography variant="body2">You must comply with all of the terms and conditions of these TOS, the
                        applicable agreements and policies referred to below, and all applicable laws, regulations and
                        rules when you use the Service and the Website.</Typography>
                    <Typography variant="h3">4. YOUR LICENSE TO USE THE WEBSITE AND THE SERVICE</Typography>
                    <Typography variant="body2">4.1 TuneMyBass.com solely and exclusively owns all intellectual property
                        and other right, title and interest in and to the Service and Website, except as expressly
                        provided for in these TOS. For example and without limitation, TuneMyBass.com owns the name,
                        graphics and design of the Website and certain technology used in providing the Service. You
                        will not acquire any right, title or interest therein under these TOS or otherwise.</Typography>
                    <Typography variant="body2">4.2 TuneMyBass.com grants you a limited revocable license to access and
                        use the Website and the Service for its intended purposes, subject to your compliance with these
                        TOS. This license does not include the right to collect or use information contained on the
                        Website for purposes prohibited by TuneMyBass.com; create derivative works based on the content
                        of the Website; or download or copy the Website. If you use the Website in a manner that exceeds
                        the scope of this license or breach these TOS, TuneMyBass.com may revoke the license granted to
                        you.</Typography>
                    <Typography variant="h3">5. THIRD-PARTY SERVICES</Typography>
                    <Typography variant="body2">TuneMyBass.com may use third parties to provide certain services
                        accessible through the Website. TuneMyBass.com does not control those third parties or their
                        services, and you agree that TuneMyBass.com will not be liable to you in any way for your use of
                        such services. These third parties may have their own terms of use and other policies. You must
                        comply with such terms and policies as well as these TOS when you use these services. If any
                        such terms or policies conflict with TuneMyBass.com's TOS, agreements or policies, you must
                        comply with TuneMyBass.com's TOS, agreements or policies, as applicable.</Typography>
                    <Typography variant="h3">6. DEALINGS WITH ADVERTISERS </Typography>
                    <Typography variant="body2">Your correspondence or business dealings with, or participation in
                        promotions of, advertisers found on or through the Service, including payment and delivery of
                        related goods or services, and any other terms, conditions, warranties or representations
                        associated with such dealings, are solely between you and such advertiser. You agree that
                        TuneMyBass.com shall not be responsible or liable for any loss or damage of any sort incurred as
                        the result of any such dealings or as the result of the presence of such advertisers on the
                        Service.</Typography>
                    <Typography variant="h3">7. LINKS </Typography>
                    <Typography variant="body2">The Service may provide, or third parties may provide, links to other
                        World Wide Web sites or resources. Because TuneMyBass.com has no control over such sites and
                        resources, you acknowledge and agree that TuneMyBass.com is not responsible for the availability
                        of such external sites or resources, and does not endorse and is not responsible or liable for
                        any Content, advertising, products, or other materials on or available from such sites or
                        resources. You further acknowledge and agree that TuneMyBass.com shall not be responsible or
                        liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in
                        connection with use of or reliance on any such Content, goods or services available on or
                        through any such site or resource.</Typography>
                    <Typography variant="h3">8. NO RESALE OF SERVICE </Typography>
                    <Typography variant="body2">You agree not to reproduce, duplicate, copy, sell, trade, resell or
                        exploit for any commercial purposes, any portion of the Service or Website, use of the Service
                        or Website, or access to the Service or Website.</Typography>
                    <Typography variant="h3">9. USER CONDUCT</Typography>
                    <Typography variant="body2">You understand that all information, data, text, software, music, sound,
                        photographs, graphics, video, messages or other materials (&ldquo;Content&rdquo;), whether
                        publicly posted or privately transmitted, are the sole responsibility of the person from which
                        such Content originated. This means that you, and not TuneMyBass.com, are entirely responsible
                        for all Content that you upload, post, email, transmit or otherwise make available via the
                        Service. TuneMyBass.com does not control the Content posted via the Service and, as such, does
                        not guarantee the accuracy, integrity or quality of such Content. You understand that by using
                        the Service, you may be exposed to Content that is offensive, indecent or objectionable. Under
                        no circumstances will TuneMyBass.com be liable in any way for any Content, including, but not
                        limited to, for any errors or omissions in any Content, or for any loss or damage of any kind
                        incurred as a result of the use of any Content posted, emailed, transmitted or otherwise made
                        available via the Service.</Typography>
                    <Typography variant="body2">You agree to not use the Service to:</Typography>
                    <ol type="a" start="1">
                        <li>upload, post, email, transmit or otherwise make available any Content that is unlawful,
                            harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous,
                            invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable;
                        </li>
                        <li>harm minors in any way;</li>
                        <li>impersonate any person or entity, including, but not limited to, a TuneMyBass.com
                            associate, administrator, or falsely state or otherwise misrepresent your
                            affiliation with a person or entity;
                        </li>
                        <li>forge headers or otherwise manipulate identifiers in order to disguise the
                            origin of any Content transmitted through the Service;
                        </li>
                        <li>upload, post, email, transmit or otherwise make available any Content that
                            you do not have a right to make available under any law or under contractual
                            or fiduciary relationships;
                        </li>
                        <li>upload, post, email, transmit or otherwise make available any Content
                            that infringes any patent, trademark, trade secret, copyright or other
                            proprietary rights (&ldquo;Rights&rdquo;) of any party;
                        </li>
                        <li>upload, post, email, transmit or otherwise make available any
                            unsolicited or unauthorized advertising, promotional
                            materials, &ldquo;junk mail,&rdquo; &ldquo;spam,&rdquo; &ldquo;chain
                            letters,&rdquo; &ldquo;pyramid schemes,&rdquo; or any other form of
                            solicitation;
                        </li>
                        <li>upload, post, email, transmit or otherwise make available any
                            material that contains software viruses or any other computer
                            code, files or programs designed to interrupt, destroy or limit
                            the functionality of any computer software or hardware or
                            telecommunications equipment;
                        </li>
                        <li>interfere with or disrupt the Service or servers or networks
                            connected to the Service, or disobey any requirements,
                            procedures, policies or regulations of networks connected to
                            the Service;
                        </li>
                        <li>intentionally or unintentionally violate any applicable
                            local, state, national or international law;
                        </li>
                        <li>provide material support or resources (or to conceal
                            or disguise the nature, location, source, or
                            ownership of material support or resources) to any
                            organization(s) designated by the United States
                            government as a foreign terrorist organization
                            pursuant to section 219 of the Immigration and
                            Nationality Act;
                        </li>
                        <li>collect or store personal data about other users
                            in connection with the prohibited conduct and
                            activities set forth in paragraphs a through m
                            above.
                        </li>
                    </ol>
                    <Typography variant="body2">You acknowledge that TuneMyBass.com may or may not pre-screen Content,
                        but that TuneMyBass.com and its designees shall have the right (but not the obligation) in their
                        sole discretion to pre-screen, refuse, or move any Content that is available via the Service.
                        Without limiting the foregoing, TuneMyBass.com and its designees shall have the right to remove
                        any Content that violates the TOS or is otherwise objectionable. You agree that you must
                        evaluate, and bear all risks associated with, the use of any Content, including any reliance on
                        the accuracy, completeness, or usefulness of such Content. In this regard, you acknowledge that
                        any reliance on any Content created by TuneMyBass.com or submitted to TuneMyBass.com and
                        in all other parts of the Service and the Website, is exclusively at your own risk.</Typography>
                    <Typography variant="body2">You acknowledge, consent and agree that TuneMyBass.com may access,
                        preserve, and disclose your user information and Content if required to do so by law or in a
                        good faith belief that such access preservation or disclosure is reasonably necessary to: (a)
                        comply with legal process; (b) enforce the TOS; (c) respond to claims that any Content violates
                        the rights of third-parties; (d) respond to your requests for customer service; or (e) protect
                        the rights, property, or personal safety of TuneMyBass.com, its users and the
                        public.</Typography>
                    <Typography variant="body2">You understand that the technical processing and transmission of the
                        Service, including your Content, may involve (a) transmissions over various networks; and (b)
                        changes to conform and adapt to technical requirements of connecting networks or
                        devices.</Typography>
                    <Typography variant="h3">10. DISCLAIMER OF WARRANTIES </Typography>
                    <Typography variant="body2">YOU EXPRESSLY UNDERSTAND AND AGREE THAT:</Typography>
                    <ol type="a" start="1">
                        <li>YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN &ldquo;AS
                            IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS. TUNEMYBASS.COM EXPRESSLY DISCLAIMS ALL
                            WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE
                            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                            NON-INFRINGEMENT.
                        </li>
                        <li>TUNEMYBASS.COM MAKES NO WARRANTY THAT (i) THE SERVICE WILL MEET YOUR REQUIREMENTS, (ii)
                            THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT
                            MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, (iv) THE
                            QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED
                            BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS, AND (V) ANY ERRORS IN THE
                            SOFTWARE OR THE WEBSITE WILL BE CORRECTED.
                        </li>
                        <li>ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE
                            AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY
                            DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY
                            SUCH MATERIAL.
                        </li>
                        <li>NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
                            TUNEMYBASS.COM OR THROUGH OR FROM THE SERVICE SHALL CREATE ANY WARRANTY NOT
                            EXPRESSLY STATED IN THE TOS.
                        </li>
                        <li>A SMALL PERCENTAGE OF USERS MAY EXPERIENCE EPILEPTIC SEIZURES WHEN EXPOSED
                            TO CERTAIN LIGHT PATTERNS OR BACKGROUNDS ON A COMPUTER SCREEN OR WHILE USING
                            THE SERVICE. CERTAIN CONDITIONS MAY INDUCE PREVIOUSLY UNDETECTED EPILEPTIC
                            SYMPTOMS EVEN IN USERS WHO HAVE NO HISTORY OF PRIOR SEIZURES OR EPILEPSY. IF
                            YOU, OR ANYONE IN YOUR FAMILY, HAVE AN EPILEPTIC CONDITION, CONSULT YOUR
                            PHYSICIAN PRIOR TO USING THE SERVICE. IMMEDIATELY DISCONTINUE USE OF THE
                            SERVICE AND CONSULT YOUR PHYSICIAN IF YOU EXPERIENCE ANY OF THE FOLLOWING
                            SYMPTOMS WHILE USING THE SERVICE -- DIZZINESS, ALTERED VISION, EYE OR MUSCLE
                            TWITCHES, LOSS OF AWARENESS, DISORIENTATION, ANY INVOLUNTARY MOVEMENT, OR
                            CONVULSIONS.
                        </li>
                    </ol>
                    <Typography variant="h3">11. LIMITATION OF LIABILITY </Typography>
                    <Typography variant="body2">YOU EXPRESSLY UNDERSTAND AND AGREE THAT TUNEMYBASS.COM SHALL NOT BE
                        LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
                        INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER
                        INTANGIBLE LOSSES (EVEN IF TUNEMYBASS.COM HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
                        RESULTING FROM: (i) THE USE OR THE INABILITY TO USE THE SERVICE; (ii) THE COST OF PROCUREMENT OF
                        SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED
                        OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE; (iii)
                        UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT
                        OF ANY THIRD PARTY ON THE SERVICE; OR (v) ANY OTHER MATTER RELATING TO THE SERVICE.</Typography>
                    <Typography variant="body2">UNDER NO CIRCUMSTANCES WILL TUNEMYBASS.COM BE RESPONSIBLE FOR ANY LOSS
                        OR DAMAGE, INCLUDING BUT NOT LIMITED TO, DAMAGES TO PROPERTY, THEFT, PERSONAL INJURY OR DEATH,
                        RESULTING FROM ANYONE'S USE OF THE WEBSITE OR THE SERVICE, ANY CONTENT POSTED ON THE WEBSITE OR
                        TRANSMITTED TO USERS, OR ANY INTERACTIONS BETWEEN USERS OF THE WEBSITE, WHETHER ONLINE OR
                        OFFLINE.</Typography>
                    <Typography variant="h3">12. INDEMNITY </Typography>
                    <Typography variant="body2">You agree to indemnify and hold TuneMyBass.com, and its owners,
                        subsidiaries, affiliates, officers, agents, co-branders or other partners, and employees,
                        harmless from any claim or demand, including reasonable attorneys' fees, made by any third party
                        due to or arising out of Content you submit, post, transmit or make available through the
                        Service, your use of the Service, your connection to the Service, your violation of the TOS, or
                        your violation of any rights of another.</Typography>
                    <Typography variant="h3">13. <strong>TERMINATION</strong></Typography>
                    <Typography variant="body2">13.1 TuneMyBass.com may suspend or terminate your use of the Website or
                        Service if it believes, in its sole and absolute discretion, that you have breached a term of
                        these TOS.</Typography>
                    <Typography variant="body2">13.2 These TOS will survive indefinitely unless and until TuneMyBass.com
                        chooses to terminate them.</Typography>
                    <Typography variant="body2">13.3 If you or TuneMyBass.com terminates your use of the Website or the
                        Service, TuneMyBass.com may delete any Content or other materials relating to your use of the
                        Service on TuneMyBass.com's servers or otherwise in its possession and TuneMyBass.com will have
                        no liability to you or any third party for doing so.</Typography>
                    <Typography variant="h3">14. <strong>DISPUTE RESOLUTION</strong></Typography>
                    <Typography variant="body2">All disputes arising out of, relating to or connected with these TOS or
                        your use of any part of the Service will be exclusively resolved under confidential binding
                        arbitration held in Harris County, Texas before and in accordance with the Rules of the American
                        Arbitration Association (the &ldquo;AAA&rdquo;), by a sole arbitrator applying Texas law
                        (without regard for conflicts of law principles).&nbsp; The parties expressly agree that
                        arbitration pursuant to this provision shall be governed by the AAA&rsquo;s Commercial
                        Arbitration Rules and that the AAA&rsquo;s Supplementary Procedures for Consumer-Related
                        Disputes shall not apply.&nbsp; The arbitrator's award will be binding and may be entered as a
                        judgment in any court of competent jurisdiction. To the fullest extent permitted by applicable
                        law, no arbitration under these TOS will be joined to an arbitration involving any other party
                        subject to these TOS, whether through class arbitration proceedings or otherwise. Any action to
                        enforce an arbitrator's award will be brought in a federal or state court located in Harris
                        County, Texas, and each party hereby irrevocably submits to the personal jurisdiction of such
                        court. By entering into these TOS, you hereby irrevocably waive any right you may have to join
                        claims with those of others in the form of a class action or similar procedural device. Any
                        claim arising out of, relating to or connected with these TOS or your use of any part of the
                        Service must be asserted individually.</Typography>
                    <Typography variant="h3">15.&nbsp; SEVERABILITY</Typography>
                    <Typography variant="body2">To the extent any portion of these TOS is determined to be illegal or
                        unenforceable, these TOS shall be deemed immediately amended so as to remove only the
                        unenforceable portion, and the remainder of these TOS shall be enforced to the fullest extent
                        permitted by law and in closest conformity to the original terms and intent of these TOS as
                        permitted by law.</Typography>

                </Grid>
            </Grid>
        </>
    )
}