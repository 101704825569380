import React, {useEffect, useState} from 'react'
import {Grid, Collapse, List, ListItem, ListItemText} from '@material-ui/core'
import data from './TuningData'
import Button from "@material-ui/core/Button";
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowRight from '@material-ui/icons/ArrowRight'

const TuningSelect = (props) => {

    const [tuningListIsOpen, setTuningListIsOpen] = useState(false);

    function toggleTuningListCollapse() {
        setTuningListIsOpen(!tuningListIsOpen)
    }

    const [localStorageExists, setLocalStorageExists] = useState(false)
    useEffect(() => {
        if (localStorage.getItem('tuningID')) {
            setLocalStorageExists(true)
            handleListSelect(data[localStorage.getItem('tuningID')]['id'])
        }
        // eslint-disable-next-line
    }, [localStorageExists])

    function handleListSelect(id) {
        // console.log('listSelect: ' + id)
        setTuningTitle(data[id].tuningTitle)
        props.handleSelect(data[id])
        toggleTuningListCollapse()
    }

    const [tuningTitle, setTuningTitle] = useState('B E A D G C')

    const tuningList =
        <List tabndex="0"
              style={{
                  'border': '#f79421 1px solid',
                  'padding': '0',
                  'marginBottom': '1em'
              }}
        >
            {data.map((item, i) =>
                <ListItem key={i} button tabndex="0"
                          aria-label={item.description}
                          className={'pointer'} style={{
                    'color': tuningTitle === item.tuningTitle ? '#FFF' : '',
                    'backgroundColor': tuningTitle === item.tuningTitle ? '#F79421' : ''
                }}
                          onClick={() => handleListSelect(item.id)}
                >
                    <ListItemText>{item.tuningName}</ListItemText>
                </ListItem>
            )}
        </List>

    let tuningListDropDownArrow = tuningListIsOpen ? <ArrowDropDown aria-hidden/> : <ArrowRight aria-hidden/>;

    return (
        <Grid container justify={"center"} spacing={2} >
            <Grid container item xs={12} justify={'center'}>
                <Button tabndex="0" variant="contained" size="large" color="primary" aria-label={`Selected tuning is ${tuningTitle}`} onClick={toggleTuningListCollapse}>{tuningListDropDownArrow} Selected: {tuningTitle}</Button>
            </Grid>
            <Grid container item xs={12} justify={'center'}>
                <Collapse in={tuningListIsOpen}>
                    {tuningList}
                </Collapse>
            </Grid>
        </Grid>
    )
}
export default TuningSelect