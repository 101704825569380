import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {Helmet} from "react-helmet";

function PrivacyPolicy() {
    return (
        <>
            <Helmet>
                <title>Terms of Service</title>
            </Helmet>
            <Grid container justify="center">
                <Grid item xs={12} sm={8} md={6} spacing={8}>
                    <Typography variant={"h1"} align={"center"} gutterBottom>Privacy Policy</Typography>

                    <Typography variant="body2">This privacy policy is effective on and from July 18, 2019.</Typography>
                    <Typography variant="body2">TuneMyBass.com
                        ('the website') uses cookies and similar technologies to help provide many features and services
                        to
                        users of the website.</Typography>

                    <Typography variant="h3">General Statement
                        About TuneMyBass.com, Data, and Privacy</Typography>
                    <Typography variant="body2">TuneMyBass.com' mission is to teach users about music. It is
                        not in the business of selling or distributing personal data. Data collected by TuneMyBass.com
                        is used to
                        aid in the mission of teaching music to its users and improve its product and services for
                        future
                        users.</Typography>
                    <Typography variant="body2">This privacy policy explains what data TuneMyBass.com collects, how it is used, and your
                        rights.</Typography>
                    <Typography variant="h3">Who Is TuneMyBass.com?</Typography>
                    <Typography variant="body2">TuneMyBass.com is owned and operated by Leading Tone Media, LLC
                        located in Houston, Texas.</Typography>
                    <Typography variant="body2">Andrew Pouska is the President of Leading Tone Media, LLC, serves
                        as the data protection officer, and may be contacted at any time through this <a
                            href="https://www.studybass.com.com/feedback/">website's contact form</a>.</Typography>
                    <Typography variant="h3">Contacting
                        TuneMyBass.com</Typography>
                    <Typography variant="body2">If you have any questions, problems or need to contact TuneMyBass.com regarding this
                        privacy policy or your data, you may do so through the <a
                            href="https://www.studybass.com.com/feedback/">website contact form</a>.</Typography>
                    <Typography variant="h3">Definitions</Typography>
                    <Typography variant="body2">“<strong>TuneMyBass.com”</strong> and
                        the <strong>“Site”</strong> refer to the website available at TuneMyBass.com.</Typography>
                    <Typography variant="body2">The <strong>“Service”</strong> refers to the interactive features of the website.</Typography>
                    <Typography variant="body2">“<strong>Personal Information”</strong> refers to data such as IP address,
                        email address, and name.</Typography>
                    <Typography variant="body2">“<strong>User-Created Data”</strong> refers to data a registered user
                        voluntarily inputs into the Site.</Typography>
                    <Typography variant="body2">“<strong>User Information”</strong> refers to Personal
                        Information and User-Created Data collectively.</Typography>
                    <Typography variant="h3">What Data Does TuneMyBass.com
                    Collect?</Typography>                    
                    <Typography variant="body2"><strong>Information TuneMyBass.com collects
                        automatically</strong></Typography>
                    <Typography variant="body2"><strong>Server Log Files</strong> – When you visit a website the web
                        server logs some basic information. This may include IP addresses, browser type, ISP
                        referring/exit
                        pages, operating system, and date/time stamps. </Typography>
                    <Typography variant="body2"><strong>Login Time</strong> – TuneMyBass.com stores
                        your last login time.</Typography>
                    <Typography variant="body2"><strong>Cookies</strong> – TuneMyBass.com may use both session cookies and
                        persistent cookies. A session cookie disappears automatically after you close your browser. A
                        persistent cookie, such as a “Remember Me” cookie, remains after you close your browser and may
                        be
                        used by your browser on subsequent visits to the Website. Please see TuneMyBass.com' detailed
                        cookie
                        policy.</Typography>
                    <Typography variant="body2"><strong>Data Analytics</strong></Typography>
                    <Typography variant="body2">TuneMyBass.com collects anonymized statistics about
                        how users use the Site. Anonymized means a user's exact IP address is not logged. Examples of
                        statistical data includes user screen size or pages visited. This data is used to help improve
                        the
                        function and use of the Site.</Typography>
                    <Typography variant="body2">TuneMyBass.com uses a private analytics software package. This data
                        is only used for internal purposes and is not shared with other parties.</Typography>
                    <Typography variant="body2"><strong>Information
                        TuneMyBass.com does NOT collect</strong></Typography>
                    <Typography variant="body2">TuneMyBass.com does not collect, receive or store any financial
                        information such as credit card numbers or wallet information.</Typography>
                    <Typography variant="body2">TuneMyBass.com does not ask for, or
                        want, from its users any Sensitive Personal Data such as racial or ethnic origin, political
                        opinions, religious or philosophical beliefs, trade union membership, genetic data, biometric
                        data,
                        data concerning health or data concerning a natural person's sex life or sexual orientation.
                        TuneMyBass.com further recommends not sharing this data anywhere on the internet unless
                        absolutely
                        necessary.</Typography>
                    <Typography variant="h3">Third-Party Services</Typography>
                    <Typography variant="h4">What are Third-Party Services?</Typography>
                    <Typography variant="body2">Third-Party
                    Services are external services used by the Site which provide added functionality to the Site. These
                    third-party services may collect some information, such as IP address, from your visit to this site
                    and possibly connect it to information from other sites you visit using the same services. For
                    example, watching YouTube videos on different websites, YouTube could be aware that they were
                    watched by the same user.</Typography>
                    <Typography variant="h4">What types of third-party services does TuneMyBass.com use?</Typography>
                    <Typography variant="body2">
                    <strong>Email Services</strong></Typography>
                    <Typography variant="body2">These services help in the smooth, trusted delivery of email
                        and protect users from spam.<br/>TuneMyBass.com uses Mailgun to deliver transactional email and
                        newsletters. Read <a href="https://www.mailgun.com/privacy-policy">Mailgun's Privacy Policy</a>.
                    </Typography>
                    <Typography variant="body2">TuneMyBass.com uses ProtonMail for other emails between administrators and users.</Typography>

                    <Typography variant="body2"><strong>Content Delivery
                        Networks</strong></Typography>
                    <Typography variant="body2">Content delivery networks (CDNs) host site content such as videos, audio
                        files, pdfs and images. They ensure faster and smoother delivery of this content. These CDNs may
                        log
                        user information such as IP addresses.</Typography>
                    <Typography variant="body2"><strong>Code Libraries</strong></Typography>
                    <Typography variant="body2">A common practice on the internet is to use centralized
                        code libraries. Websites work faster by not downloading the same code libraries, such as jQuery,
                        again and again from each individual site. Instead, a central site is used and shared among
                        thousands of websites. Therefore, when you visit a website you likely have a cached copy of some
                        of
                        the code. Your web browser doesn't need to download the code again. It saves bandwidth and
                        speeds up
                        the page load time.</Typography>
                    <Typography variant="body2">Hosts of these code libraries may or may not collect information such as
                        IP addresses accessing the code libraries. TuneMyBass.com does not share any Personal
                        Information with
                        these sites.<br/></Typography>
                    <Typography variant="body2"><strong>Video Streaming Services</strong></Typography>
                    <Typography variant="body2"><strong></strong>TuneMyBass.com
                        uses embedded video streaming services such as YouTube.</Typography>
                    <Typography variant="body2">These services can collect your video
                        viewing and IP address. TuneMyBass.com does not share any Personal Information with these video
                        streaming
                        services.<strong></strong></Typography>
                    <Typography variant="body2"><strong>Payment Processors</strong></Typography>
                    <Typography variant="body2"><strong></strong>TuneMyBass.com
                        uses PayPal as a payment processor for donations and to collect payments for extra features such
                        as
                        memberships and account add-ons.</Typography>
                    <Typography variant="body2">When users kindly donate to or support TuneMyBass.com through
                        PayPal, PayPal may share information such as your name, email address, or mailing address with
                        TuneMyBass.com. This information may be connected to your TuneMyBass.com account if necessary to
                        provide the
                        Service the user has purchased.<strong></strong></Typography>
                    <Typography variant="h3">Email Communications</Typography>
                    <Typography variant="body2"><strong>Private Emails</strong><br/> If you write to
                        TuneMyBass.com for support, questions, or other matters, TuneMyBass.com will store, use and
                        reply to these
                        emails for troubleshooting problems, improving site features, fixing bugs, promotional
                        testimonials
                        (with your explicit permission), to thank you, or to contact you in regards to your musical
                        development. Your email address will not be added to any email lists.</Typography>
                    <Typography variant="h3">How Does
                    TuneMyBass.com Use
                    Your Data?</Typography>
                    <Typography variant="body2">TuneMyBass.com uses your data to provide the Site's Service. The Service includes
                    features which allow users to log their lesson progress and history, create and record practice
                    sessions, and create user notes. This user created data is not shared with third-parties.
                    TuneMyBass.com
                    may use this data internally to improve the Service.</Typography>
                    <Typography variant="h3">Who Can See Your Data?</Typography>
                    <Typography variant="body2">TuneMyBass.com
                        does not sell, rent access to, or distribute User-Created Data or Personal Information to
                        third-parties. Site administrators have access to your data.<br/> Your data is not displayed to
                        the
                        public on the Site.</Typography>
                    <Typography variant="h3">What is the Lawful Basis for Processing Personal Information?</Typography>
                    <Typography variant="body2">We
                        may Process your Personal Information where: you have given your consent; the Processing is
                        necessary for a contract between you and us; the Processing is required by applicable law; the
                        Processing is necessary to protect the vital interests of any individual; or where we have a
                        valid
                        legitimate interest in the Processing.</Typography>
                    <Typography variant="body2">In Processing your User Information in connection with
                        the purposes set out in this Policy, we may rely on one or more of the following legal bases,
                        depending on the circumstances:</Typography>
                    <ul>
                        <li>Consent: We may Process your User Information where we have obtained your prior, express
                            consent to the Processing (this legal basis is only used in relation to Processing that is
                            entirely voluntary – it is not used for Processing that is necessary or obligatory in any
                            way);
                        </li>
                        <li>Contractual necessity: We may Process your User Information where the Processing is
                            necessary in connection with any contract that you may enter into with us;
                        </li>
                        <li>Compliance with applicable law: We may Process your User Information where the Processing is
                            required by applicable law;
                        </li>
                        <li>Vital interests: We may Process your User Information where the Processing is necessary to
                            protect the vital interests of any individual; or
                        </li>
                        <li>Legitimate interests: We may Process your User Information where we have a legitimate
                            interest in carrying out the Processing for the purpose of managing, operating or promoting
                            our business, and that legitimate interest is not overridden by your interests, fundamental
                            rights, or freedoms.
                        </li>
                    </ul>
                    <Typography variant="h3">Legal Requests for Your Information</Typography>
                    <Typography variant="body2">TuneMyBass.com may disclose your information or
                    content to
                    satisfy a law, regulation, legal process, governmental request, or governmental order.
                    TuneMyBass.com'
                    policy is to give you, advance notice if we are going to release your information unless by law we
                    are prohibited from or advised against doing so. If we think that by disclosing your information we
                    may prevent serious harm to TuneMyBass.com, its users, you, or anyone else for that matter, we may
                    do so
                    without notifying you. Finally, we may disclose your information if we believe it is necessary to
                    detect, prevent, or otherwise address safety, fraud, or security concerns related to
                    TuneMyBass.com.</Typography>
                    <Typography variant="h3">How Can Users Update, Correct or Delete Their Data?</Typography>
                    <Typography variant="h3">Data Storage
                    and
                    Deletion of Accounts and Data</Typography>
                    <Typography variant="body2">We are committed to protecting the security of your
                    information. We use a variety of industry-standard security technologies and procedures designed to
                    help protect your information from unauthorized access, use, or disclosure. Despite these measures,
                    you should know that we cannot fully eliminate security risks associated with information and
                    mistakes may happen.</Typography>
                    <Typography variant="body2">If you choose to delete or deactivate your account, you can no longer
                        retrieve your content or reactivate your account, and we cannot do so for you. Also, your
                        username
                        may become available for another person to use.</Typography>
                    <Typography variant="body2">TuneMyBass.com stores its data on servers run by
                        third parties. We, or a third party we work with, may keep backups of TuneMyBass.com' data and
                        code,
                        which may or may not include your Personal Information and User-Created Data, even after you
                        have
                        deleted your account. Therefore, even after you delete your account, your data may remain in
                        backups
                        on our system, although it will be no longer visible or publicly accessible on the Site.</Typography>
                    <Typography variant="h3">International Transfers of Information</Typography>
                    <Typography variant="body2">TuneMyBass.com hosts data with hosting service
                    providers in the United States and Europe. The servers on which Personal Information is stored are
                    kept in a controlled environment. While we take reasonable efforts to guard your Personal
                    Information, no security system is impenetrable and due to the inherent nature of the Internet as an
                    open global communications vehicle, we cannot guarantee that information, during transmission
                    through the Internet or while stored on our systems or otherwise in our care, will be absolutely
                    safe from intrusion by others, such as hackers.</Typography>
                    <Typography variant="h3">Data Retention</Typography>
                    <Typography variant="body2">TuneMyBass.com will
                    retain
                    Personal Information where we have an ongoing legitimate business or legal need to do so. Our
                    retention periods will vary depending on the type of data involved, but, generally, we'll refer to
                    these criteria in order to determine retention period:</Typography>
                    <ul>
                        <li>Whether we have a legal or contractual need to retain the data.</li>
                        <li>Whether the data is necessary to provide our Services.</li>
                        <li>Whether our Members have the ability to access and delete the data within their
                            TuneMyBass.com
                            accounts.
                        </li>
                        <li>Whether our Members would reasonably expect that we would retain the data until they remove
                            it or until their TuneMyBass.com accounts are closed or terminated.
                        </li>
                    </ul>
                    <Typography variant="body2">When we have no ongoing legitimate business need to process your Personal Information, we will
                        either delete or anonymize it or, if this is not possible (for example, because your Personal
                        Information has been stored in backup archives), then we will securely store your Personal
                        Information and isolate it from any further processing until deletion is possible.</Typography>
                    <Typography variant="h3">Children</Typography>
                    <Typography variant="body2">TuneMyBass.com is not directed to individuals under 13. We do not knowingly
                    collect
                    Personal Information from children under 13. If we become aware that a child under 13 has provided
                    us with Personal Information, we will take steps to delete such information. If you become aware
                    that a child has provided us with Personal Information, please contact us through the <a
                        href="https://www.studybass.com.com/feedback/">website contact form</a>.</Typography>
                    <Typography variant="body2">If a child under the
                        age of 18 wishes to use the Site, he or she may not create an account. Instead, the child's
                        parent
                        or legal guardian must create an account in the parent's or legal guardian's name and guide and
                        supervise their child's use of the Service.</Typography>
                    <Typography variant="h3">Your Rights Regarding to Your Personal Data
                    under
                    the General Data Protection Regulation (GDPR)</Typography>
                    <Typography variant="body2">If you are an individual in the European
                    Economic Area (EEA), we collect and process information about you only where we have legal bases for
                    doing so under applicable EU laws. This means we collect and use your information only where:</Typography>
                    <ul>
                        <li>We need it to provide you the functionality of the Website, including to operate the
                            Website, provide customer support and personalized features, and to protect the safety and
                            security of the Website;
                        </li>
                        <li>It satisfies a legitimate interest (which is not overridden by your data protection
                            interests), such as for research and development, to market and promote the Website, and to
                            protect our legal rights and interests;
                        </li>
                        <li>You give us consent to do so for a specific purpose; or</li>
                        <li>We need to process your data to comply with a legal obligation.</li>
                    </ul>
                    <Typography variant="body2">If you have consented to our use of information about you for a specific purpose, you have the
                        right to change your mind at any time, but this will not affect any processing that has already
                        taken place. Where we are using your information because we or a third party have a legitimate
                        interest to do so, you may have the right to object to that use though, in some cases, this may
                        mean no longer using the Website. </Typography>
                    <Typography variant="body2">Additionally, once you have provided us your data,
                        either actively or automatically, you will have the right to: </Typography>
                    <ul>
                        <li>Request access to the personal data we have from you;</li>
                        <li>Request a rectification, erasure, restriction of processing of your personal data;</li>
                        <li>Object to processing in certain circumstances;</li>
                        <li>Data portability to retain and reuse your personal data for your own purpose;</li>
                        <li>File a complaint with a supervisory authority if you believe that we have violated any of
                            the rights concerning Personal Information about you. We encourage you to first reach out to
                            us through the <a href="https://www.TuneMyBass.com.com/feedback/">website contact
                                form</a> so we
                            have an opportunity to address your concerns directly before you do so.
                        </li>
                    </ul>
                    <Typography variant="h3">Conditions of Use and Notices</Typography>
                    <Typography variant="body2">If you choose to visit our Website, your visit and any
                    dispute over privacy is subject to this Privacy Policy and our Terms of Service, including
                    limitations on damages, arbitration of disputes, application of the law of the State of Texas and
                    venue for all disputes in Houston, Texas.</Typography>
                    <Typography variant="body2">If any provision of this Privacy Policy, Cookie
                        Policy or our Terms of Service is held invalid by a court of competent jurisdiction, the
                        remainder
                        of this Privacy Policy and our Terms of Service will remain in full force and effect. </Typography>
                    <Typography variant="h3">Updates to This Privacy Policy</Typography>
                    <Typography variant="body2">From time to time this Privacy Policy will change.
                    TuneMyBass.com will notify Users of any change to our Privacy Policy by posting the revised Privacy
                    Policy with an updated date of revision on the Site. If any significant changes are made to the
                    policy these will be called out with a short notice of the revision conspicuously posted on the
                    Website. We may also contact Users and notify them of the changes if required by
                    law. <br/> TuneMyBass.com values your privacy and will not change how any Personal Information
                    already
                    collected from Users is used in any material way without also providing notice of the change via
                    email, through this Website, or through other means, and obtaining consent via your continued use to
                    any new policies.</Typography>

                </Grid>
            </Grid>
        </>
    );
}

export default PrivacyPolicy