import React, {useState, useEffect, createRef} from "react";
import {Grid, Switch, Select, MenuItem} from '@material-ui/core';
import Slider from '@material-ui/lab/Slider';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowRight from '@material-ui/icons/ArrowRight'
import Tuner from './Tuner'
import TuningSelect from './TuningSelect'
import {WindowWidth, WindowHeight} from '../utils/WindowSize'
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";

let noteFontSize = "100px";

export default function TunerMain() {

    const tunerRef = createRef()

    const [tuningID, setTuningID] = useState(2)
    // eslint-disable-next-line
    const [viewPortWidth, setViewPortWidth] = useState("600")
    const [viewPortHeight, setViewPortHeight] = useState("800")
    const [isMobile, setIsMobile] = useState(false)
    // eslint-disable-next-line
    const [activeString, setActiveString] = useState("Press")
    // eslint-disable-next-line
    const [selectedTuning, setSelectedTuning] = useState(3)
    const [volume, setVolume] = useState(
        Number(localStorage.getItem('volume')) || -4)
    const [wetLevel, setWetLevel] = useState(Number(localStorage.getItem('distortionLevel')) || 0.2)
    let distOnDefault = false
    if (localStorage.getItem('distortion') === 'true') {
        distOnDefault = true
    }

    const [showHelp, setShowHelp] = useState(false)
    let showHelpArrow = showHelp ? <ArrowDropDown style={{'height': '.875em'}} aria-hidden/> :
        <ArrowRight style={{'height': '.875em'}} aria-hidden/>;

    const [distCollapsed, setDistCollapsed] = useState(false)
    let distDropDownArrow = distCollapsed ? <ArrowDropDown style={{'height': '.875em'}} aria-hidden/> :
        <ArrowRight style={{'height': '.875em'}} aria-hidden/>;
    const [distOn, setDistOn] = useState(distOnDefault)

    // eslint-disable-next-line
    const [beenHere, setBeenHere] = useState(Boolean(localStorage.getItem('beenHere')) || false)

    const windowHeight = WindowHeight().height;
    const windowWidth = WindowWidth().width;

    const [svgPaddingBottom, setSvgPaddingBottom] = useState('75%') // 100% when under 776px screenWidth
    const [scalingSVGH, setScalingSVGH] = useState('100%')
    const [scalingSVGW, setScalingSVGW] = useState('100%')

    useEffect(() => {
        // calculate svg h & w scaling
        if (windowWidth <= 600) {
            // breakpoint stacks controls and svg container
            setScalingSVGH('100%')
            setScalingSVGW('100%')
        } else {
            let scale = 160000 / windowWidth
            if (scale < 125) {
                scale = 200
            }
            const scaleText = scale + '%'
            setScalingSVGH(scaleText)
            setScalingSVGW(scaleText)
        }

        if (windowWidth < 776) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [windowWidth, windowHeight])

    function formatter(value) {
        return `${value}dB`;
    }

    function handleVolumeChange(event, value) {
        // console.log(value)
        localStorage.setItem('volume', value)
        setVolume(value)
    }

    function stepVolume(amt) {
        const value = volume + amt
        handleVolumeChange(null, value)
    }

    function handleDistOnSwitch(event) {
        localStorage.setItem('distortion', event.target.checked)
        setDistOn(event.target.checked)
    }

    function handleDistLevelChange(event) {
        localStorage.setItem('distortionLevel', event.target.value)
        setWetLevel(event.target.value)
    }

    function handleSelectTuning(tuning) {
        let svgPB = '100%'
        let vph = 800
        if (tuning.numStrings === 4) {
            vph = 600
            if (windowWidth < 760) {
                svgPB = '75%'
            }
        } else if (tuning.numStrings === 5) {
            vph = 700
            if (windowWidth < 600) {
                svgPB = '125%'
            } else {
                svgPB = '87%'
            }
        } else if (tuning.numStrings === 6) {
            if (windowWidth < 600) {
                svgPB = '150%'
            }
        }
        setViewPortHeight(vph)
        setSvgPaddingBottom(svgPB)
        localStorage.setItem('tuningID', tuning['id']);
        setTuningID(tuning['id'])
        tunerRef.current.focus();
    }

    function handleDistClick() {
        setDistCollapsed(!distCollapsed)
    }

    function toggleHelp() {
        setShowHelp(!showHelp)
    }

    return (
        <Grid container id="tuner-main" style={{'minHeight': '400px', 'paddingTop': '1em'}} className={'tuner'}
              direction={'row'}
              justify={'center'} spacing={2}>

            <Grid item id="tuner-controls" xs={12} sm={6} lg={4}>
                <Grid item id="tuning-select-item" xs={12}>
                    <TuningSelect selectedTuning={selectedTuning} handleSelect={handleSelectTuning}/>

                    <Grid container spacing={2} justify="center" style={{'marginBottom': '1em'}}>
                        <Grid item>
                            <VolumeDown className="pointer" onClick={() => stepVolume(-2)} aria-label="Lower volume"/>
                        </Grid>
                        <Grid container item xs={4} justify="center">
                            <Slider id="volume-slider-in-decibels"
                                    value={volume}
                                    min={-60} max={4}
                                    onChange={handleVolumeChange}
                                    aria-label="Volume slider"
                                    aria-labelledby="continuous-slider"
                                    valueLabelDisplay={"auto"}
                                    valueLabelFormat={formatter}
                            />
                        </Grid>
                        <Grid item>
                            <VolumeUp className="pointer" onClick={() => stepVolume(2)} aria-label="Raise volume"/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} justify="center">
                        <Grid container justify="center">
                            <Button tabndex="0" variant="outlined" size="small" color="default"
                                    onClick={handleDistClick}>{distDropDownArrow}Distortion</Button>
                        </Grid>
                        <Collapse in={distCollapsed}>
                            <Grid container justify="center">
                                <p style={{'color': 'darkgrey', 'textAlign': 'center'}}>Try with small speakers.</p>
                            </Grid>

                            <Grid container component="label" justify="center" spacing={2}>
                                <Grid item>Off</Grid>
                                <Grid item>
                                    <Switch
                                        checked={distOn}
                                        onChange={handleDistOnSwitch}
                                        value="distOn"
                                        aria-label="Toggle distortion"
                                    />
                                </Grid>
                                <Grid item>On</Grid>
                            </Grid>
                            <Grid container justify="center" spacing={2}>
                                <Grid item>Amount: </Grid>
                                <Select id="distAmt"
                                        value={wetLevel}
                                        name="distortion level"
                                        aria-label="Distortion level"
                                        type='select'
                                        label="level"
                                        onChange={handleDistLevelChange}>
                                    <MenuItem value={0.2}>Light</MenuItem>
                                    <MenuItem value={0.45}>Medium</MenuItem>
                                    <MenuItem value={0.95}>Heavy</MenuItem>
                                </Select>
                            </Grid>
                        </Collapse>
                    </Grid>
                </Grid>
                <Grid container justify="center" style={{'margin': '2em 0 0 0'}}>
                    <Button tabndex="0" size="small" color="primary"
                            onClick={toggleHelp}>{showHelpArrow}{showHelp ? 'Hide' : 'Show'} Help</Button>
                </Grid>
                <Collapse in={showHelp}>
                    <Grid container justify="center" style={{
                        'backgroundColor': '#e7e7e7',
                        'borderRadius': '4px',
                        'padding': '0 1em 1em 1em',
                        'margin': '1em 0'
                    }}>
                        <Grid item id="keyboard-shortcuts" xs={8}>
                            <h3>Using the Tuner</h3>
                            <p>Select a tuning from the list.</p>
                            <p>Click the string to start playing the reference note.</p>
                            <p>Click again to stop, or click another string.</p>
                            <h3>Distortion Setting</h3>
                            <p>Adding distortion may make hearing the notes easier on phones and small speakers.</p>
                            <h3>Keyboard Shortcuts</h3>
                            <p>[s] Stop</p>
                            <p>Common tuning notes: [b] [e] [a] [d] [g]</p>
                            <p>[spacebar] activates and advances notes of selected tuning.</p>
                            <h3>Troubleshooting</h3>
                            <p>If you experience audio issues, try refreshing the page or another browser.</p>
                            <Grid container justify="center" style={{'margin': '2em 0 0 0'}}>
                                <Button tabndex="0" size="small" color="primary" onClick={toggleHelp}>X Close
                                    Help</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>
            <Grid tabIndex={0} ref={tunerRef} style={{'outline': 'none'}} item id="bass-tuner" xs={12} sm={6} lg={4}>

                <Grid item xs={12} className={`${beenHere ? 'hidden' : ''}`}
                      style={{
                          'color': 'darkgrey',
                          'display': 'inlineBlock',
                          'textAlign': 'center',
                          'width': '100%',
                          'marginBottom': '1em'
                      }}>Click notes to start/stop.<span className={`${isMobile ? 'hidden' : ''}`}>
                        <br/>Use [spacebar] to activate/advance.</span>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Tuner tuningID={tuningID}
                           fontSize={noteFontSize}
                           vpw={viewPortWidth} vph={viewPortHeight}
                           svgPaddingBottom={svgPaddingBottom}
                           scalingSVGH={scalingSVGH} scalingSVGW={scalingSVGW}
                           selectedTuning={selectedTuning}
                           activeString={activeString}
                           volume={volume}
                           distOn={distOn} wetLevel={wetLevel}
                           width="100%"
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}