import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {Helmet} from "react-helmet";

function CookiePolicy() {
    return (
        <>
            <Helmet>
                <title>Cookie Policy</title>
            </Helmet>
            <Grid container justify="center">
                <Grid item xs={12} sm={8} md={6} spacing={8}>
                    <Typography variant={"h1"} align={"center"} gutterBottom>Cookie Policy</Typography>
                    <Typography variant="body2">This cookie policy is effective on and from July 18, 2019.</Typography>
                    <Typography variant="body2">TuneMyBass.com
                    ('the website') uses cookies and similar technologies to help provide many features and services to
                    users of the website.</Typography>
                    <Typography variant={"h3"}>WHAT IS A COOKIE?</Typography>
                    <Typography variant="body2">Cookies are small text files which are
                    downloaded to your computer or mobile device when you visit a website or application. Your web
                    browser (such as Internet Explorer, Mozilla Firefox or Google Chrome) then sends these cookies back
                    to the website or application on each subsequent visit so that they can recognize you and remember
                    things like personalized details or user preferences.</Typography>
                    <Typography variant="body2">What are “similar technologies”?
                    Technologies like “Local Storage” function similarly to cookies. Local Storage can store larger
                    amounts of data, for instance an mp3 audio file, which saves bandwidth for both users and websites
                    by not needing to download the same data again.</Typography>
                    <Typography variant="body2">The use of the term “cookie” herein refers to
                    cookies as well as these similar technologies.</Typography>
                    <Typography variant={"h3"}>FIRST-PARTY COOKIES</Typography>
                    <Typography variant="body2">TuneMyBass.com sets
                    cookies of its own in order to function properly.</Typography>
                    <Typography variant="body2">Some examples of TuneMyBass.com features
                    requiring cookies include keeping a user logged in from one page to the next or timing a user's
                    answers to an educational quiz. The information in these cookies is minimal, safe and is not shared
                    with third parties.</Typography>
                    <Typography variant={"h3"}>THIRD-PARTY COOKIES</Typography>
                    <Typography variant="body2">TuneMyBass.com tries to rely on direct user support
                    and tries to keep use of third-party services to a minimum. This is difficult and TuneMyBass.com uses
                    some services which may set their own cookies in your browser.</Typography>
                    <Typography variant="body2">Examples of third-party
                    services include:</Typography>
                    <ul>
                        <li>Advertisers – Google Adsense, Amazon CPM Ads. These popular ad services serve ads across the
                            web. TuneMyBass.com is experimenting with dropping these services, but they may be reinstated at
                            any time.
                        </li>
                        <li>Affiliate marketing links – Commission Junction, Amazon Associates. These are links to
                            products for which TuneMyBass.com receives a small commission at no extra cost to you. These
                            services require setting a cookie to track your purchase and credit TuneMyBass.com.
                        </li>
                        <li>Analytics software – Matamo. Analytics software helps websites understand how its users use
                            and navigate the website. It tracks things such as pages visited, length of time spent on a
                            page, what type of device was used, screen size and similar data. TuneMyBass.com uses its own
                            private analytics software.Your specific IP address is anonymized and not tracked. Analytics
                            data is not shared with third-parties.
                        </li>
                    </ul>
                    <Typography variant={"h3"}>HOW TO DISABLE AND MANAGE COOKIES</Typography>
                    <Typography variant="body2">Modern browsers allow users to delete and block
                    cookies. You may block TuneMyBass.com cookies, but it may break many features of the website. To use
                    TuneMyBass.com as designed you will need to accept its first-party cookies. </Typography><Typography variant="body2">Blocking third-party
                    cookies should not adversely affect your use of the website, but it may negatively impact revenue
                    which helps keep TuneMyBass.com online and available to you. TuneMyBass.com subscribers are exposed to few if
                    any third-party cookies.</Typography><Typography variant="body2">To learn more about managing cookies in your browser, visit the help
                    pages of your particular browser. Common browsers are linked below.</Typography><Typography variant="body2"><strong>Google
                    Chrome</strong><br/><a href="http://support.google.com/chrome/bin/answer.py?hl=en&amp;answer=95647"
                                           target="">http://support.google.com/chrome/bin/answer.py?hl=en&amp;answer=95647</a><br/><br/><strong>Firefox</strong><br/><a
                    href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
                    target="">https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer</a><br/><br/><strong>Safari</strong><br/><a
                    href="https://support.apple.com/kb/PH21411?locale=en_US"
                    target="">https://support.apple.com/kb/PH21411?locale=en_US</a><br/><strong><br/>Safari iOS</strong><br/><a
                    href="https://support.apple.com/en-us/HT201265"
                    target="">https://support.apple.com/en-us/HT201265</a><br/><br/><strong>Android</strong><br/><a
                    href="https://support.google.com/chrome/answer/114662?hl=en"
                    target="">https://support.google.com/chrome/answer/114662?hl=en</a><br/><strong><br/>Microsoft
                    Internet Explorer</strong><br/><a
                    href="https://support.microsoft.com/en-us/search?query=manage%20cookies"
                    target="">https://support.microsoft.com/en-us/search?query=manage%20cookies</a></Typography>
                </Grid>
            </Grid>
        </>
    );
}

export default CookiePolicy