import { useState, useEffect } from 'react'
import _debounce from 'lodash.debounce'

export const WindowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = _debounce(() => setWidth(window.innerWidth), 100)

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    return {width}
}

export const WindowHeight = () => {
    const [height, setHeight] = useState(window.innerHeight)

    useEffect(() => {
        const handleResize = _debounce(() => setHeight(window.innerHeight), 100)

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    return {height}
}

export const justAnAlert = () => {
    alert('hello');
};