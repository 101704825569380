import React from 'react';
import TunerMain from './TunerMain';
import {Grid, Typography} from '@material-ui/core';
import {Helmet} from "react-helmet";

function Home() {

    return (
        <Grid container justify="center">
            <Helmet>
                <title>Tune My Bass!</title>
                {/*<meta name="description" content="Nested component" />*/}
                {/*<meta name="keywords" content="bass, tuner, tuning, notes, pitch, E, A, D, G, B, C, drop D, online, tune, how to tune, reference, tones" />*/}
                {/*<link rel="canonical" href="http://www.tunemybass.com" />*/}
            </Helmet>
            <Typography variant="h1" align={"center"} gutterBottom>Tune My Bass!</Typography>
            <TunerMain/>
        </Grid>
    );

}

export default Home
