import React, {useState} from 'react';
import {BrowserRouter, Switch, Route, Link} from 'react-router-dom'
import './App.css';
import {CssBaseline, Container, Typography} from "@material-ui/core";
import {ThemeProvider, makeStyles} from '@material-ui/styles';
import theme from './Components/TMBTheme';
import TMBHeader from './Components/TMBHeader'
import Home from './Components/Home';
import HowToTune from './Components/HowToTune';
import About from './Components/About';
import CookiePolicy from './Components/CookiePolicy'
import PrivacyPolicy from './Components/PrivacyPolicy'
import TOS from './Components/TOS'
import Page404 from './Components/Page404'
// import Fretboard from './Components/Fretboard/Fretboard';
// import Quiz from "./Components/Quiz";
// import Test from "./Components/Test";
import AriaLive from "./Components/AriaLive";

const useStyles = makeStyles(theme => ({
        mainContainer: {
            margin: '0',
            padding: '0'
        },
        content: {
            paddingTop: '2em',
            marginBottom: '138px'
        },
        footer: {
            backgroundColor: '#888',
            margin: '40px 0 0 0',
            padding: '12px 0',
            // position: 'fixed',
            bottom: '0',
            width: '100%'
        },
    })
)

function App() {
    // eslint-disable-next-line
    const [ariaLiveMessage, setAriaLiveMessage] = useState("Select a tuning and cycle through the notes using the space bar. Press s to stop.")

    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <div className="myapp">
                <CssBaseline/>
                {/*<ThemeProvider theme={classes.theme}>*/}
                <AriaLive message={ariaLiveMessage}/>
                <BrowserRouter>
                    <Container classes={{'root': classes.mainContainer}} maxWidth={false}>
                        {/* Header */}
                        <TMBHeader/>
                        {/* End header */}

                        <main className={classes.content}>
                            <Container elevation={0}>
                                <Switch>
                                    <Route exact path='/' component={Home}/>
                                    <Route exact path='/how-to-tune' component={HowToTune}/>
                                    {/*<Route exact path='/quiz' component={Quiz}/>*/}
                                    <Route exact path='/about' component={About}/>
                                    <Route exact path='/cookie-policy' component={CookiePolicy}/>
                                    <Route exact path='/privacy-policy' component={PrivacyPolicy}/>
                                    <Route exact path='/tos' component={TOS}/>
                                    {/*<Route exact path='/fretboard' component={Fretboard}/>*/}
                                    <Route component={Page404}/>
                                </Switch>
                            </Container>
                        </main>

                        {/* Footer */}
                        <footer className={classes.footer}>
                            <Container maxWidth={false}>
                                <Typography variant="h6" align="center">
                                    TuneMyBass!
                                </Typography>
                                <Typography variant="body2" align="center" color="textSecondary" component="p">
                                    ©2019 Leading Tone Media, LLC - all rights reserved
                                </Typography>
                                <Typography variant="body2" align="center" color="textSecondary" component="p">
                                    <Link to={'/cookie-policy'}>cookie policy</Link> - <Link to={'/privacy-policy'}>privacy
                                    policy</Link> - <Link to={'/tos'}>terms of service</Link>
                                </Typography>
                            </Container>
                        </footer>
                        {/* End footer */}

                    </Container>
                </BrowserRouter>
            </div>
        </ThemeProvider>
    );
}

export default App
