// eslint-disable-next-line
import React from 'react'
import { useHotkeys } from 'react-hotkeys-hook';

const HotKey = (props) => {
    useHotkeys('space', (event, handler) => space(event));
    useHotkeys('4,Numpad+4', (event, handler) => key4(event));
    useHotkeys('5', (event, handler) => key5(event));
    useHotkeys('6', (event, handler) => key6(event));
    useHotkeys('s', (event, handler) => stopSound(event));
    useHotkeys('b', (event, handler) => keyB(event));
    useHotkeys('e', (event, handler) => keyE(event));
    useHotkeys('a', (event, handler) => keyA(event));
    useHotkeys('d', (event, handler) => keyD(event));
    useHotkeys('g', (event, handler) => keyG(event));

    function space(event) {
        event.preventDefault();
        props.handleSpace()
        // console.log('space')
    }

    function key4(event) {
        event.preventDefault();
        props.handle4Key()
        // console.log('4')
    }
    function key5(event) {
        event.preventDefault();
        props.handle5Key()
        // console.log('5')
    }
    function key6(event) {
        event.preventDefault();
        props.handle6Key()
        // console.log('6')
    }
    function stopSound(event) {
        event.preventDefault();
        props.handleSKey()
        // console.log('stop')
    }
    function keyB(event) {
        event.preventDefault();
        props.handleBKey()
        // console.log('B')
    }
    function keyE(event) {
        event.preventDefault();
        props.handleEKey()
        // console.log('E')
    }
    function keyA(event) {
        event.preventDefault();
        props.handleAKey()
        // console.log('A')
    }
    function keyD(event) {
        event.preventDefault();
        props.handleDKey()
        // console.log('D')
    }
    function keyG(event) {
        event.preventDefault();
        props.handleGKey()
        // console.log('G')
    }


    return (
        <React.Fragment/>
    );
};

export default HotKey;