import React from 'react';
import {Helmet} from "react-helmet";
import {Grid, Typography} from '@material-ui/core';

export default function HowToTune() {
    return (
        <>
            <Helmet>
                <title>How to Tune Your Bass</title>
            </Helmet>
            <Grid container justify="center">
                <Grid item xs={12} sm={8} md={6}>
                    <Typography variant={"h1"} align={"center"} gutterBottom>How to Tune Your Bass</Typography>
                    <Typography variant="body1" gutterBottom>Knowing how to tune your bass is an essential step to learning how to play the bass. Here is my three-part video series on <b>how to tune your bass</b>. You can find more of my bass
                        lessons on <a href="https://www.studybass.com/">StudyBass</a>.</Typography>
                    <div className="video-container">
                        <iframe title="How to Tune a Bass Video Part 1 of 3" src="https://www.youtube-nocookie.com/embed/OEV_FQ7--WU" width="600" height="338"
                                frameBorder="0" allowFullScreen="allowfullscreen"></iframe>
                    </div>
                    <div className="video-container">
                        <iframe title="How to Tune a Bass Video Part 2 of 3" src="https://www.youtube-nocookie.com/embed/ayXgU3o28FE" width="600" height="338"
                                frameBorder="0" allowFullScreen="allowfullscreen"></iframe>
                    </div>
                    <div className="video-container">
                        <iframe title="How to Tune a Bass Video Part 3 of 3" src="https://www.youtube-nocookie.com/embed/NGhXGl2mPdY" width="600" height="338"
                                frameBorder="0" allowFullScreen="allowfullscreen"></iframe>
                    </div>
                </Grid>
            </Grid>
        </>
    )

}