import React, {useState, useEffect, useRef} from 'react'
import Tone from 'tone'
import HotKey from "./HotKey";
import data from './TuningData'
import AriaLive from "./AriaLive";

const Tuner = (props) => {

    let STYLE = {
        stopColor1: '#BCBEC0',
        stopColor2: '#939598',
        stopColor3: '#BCBEC0',
        stopColor4: '#939598'
    }

    let player = useRef(new Tone.Player())
    // eslint-disable-next-line
    const [dist, setDist] = useState(new Tone.Distortion(props.wetLevel).toMaster())
    const [volume, setVolume] = useState(props.volume)
    // eslint-disable-next-line
    const [loop, setLoop] = useState(true)
    const [activeString, setActiveString] = useState(null)
    const [strClass, setStrClass] = useState(['', '', '', '', '', ''])
    const [tuning, setTuning] = useState(
        {
            0: {string: 0, name: "B", midi: 35, pitch: "B0", aria:"B"},
            1: {string: 1, name: "E", midi: 40, pitch: "E1", aria:"E"},
            2: {string: 2, name: "A", midi: 45, pitch: "A1", aria:"A"},
            3: {string: 3, name: "D", midi: 50, pitch: "D2", aria:"D"},
            4: {string: 4, name: "G", midi: 55, pitch: "G2", aria:"G"},
            5: {string: 5, name: "C", midi: 60, pitch: "C3", aria:"C"}
        }
    )
    const [ariaMessage, setAriaMessage] = useState(null)

    useEffect(() => {
        return function cleanup() {
            console.log('cleanup')
            player.current.stop()
        }
    }, [])

    // init tuning
    useEffect(() => {
        let id = props.tuningID
        activateString(null)
        setTuning(data[id]['tuning'])
        // eslint-disable-next-line
    }, [props.tuningID])


    useEffect(() => {
        // console.log('useEffect called tuning.length=' + Object.keys(tuning).length)
        if (activeString !== null && activeString < Object.keys(tuning).length) {
            let strClassArr = setStringVibrations(activeString)
            setStrClass(strClassArr)
            player.current.stop()
            playNote(activeString)
        } else {
            setStrClass(['', '', '', '', '', ''])
            player.current.stop()
        }
        // eslint-disable-next-line
    }, [activeString, tuning])

    useEffect(() => {
        // console.log('useEffect called on volume change')
        setVolume(props.volume)
        player.current.volume.value = volume;

    }, [props.volume, volume])

    useEffect(() => {
        // console.log('useEffect called on dist change')
        let distWetLevel = props.wetLevel;
        if (!props.distOn) {
            distWetLevel = 0;
        }
        dist.wet.value = distWetLevel
    }, [props.distOn, props.wetLevel, dist.wet.value])

    const activateString = (id) => {
        // console.log('activating string id: ' + id)
        if (id === activeString) {
            setActiveString(null)
        } else {
            setActiveString(id)
            localStorage.setItem('beenHere', true)
        }
    }

    const playNote = (id) => {
        // console.log("playNote " + id)
        if (loop !== (null || undefined)) {
            Tone.Transport.cancel(0)
        }

        if (id < 0 || id >= tuning.length) {
            // exit if out of range or same string
            // console.log("string id out of range")
            return
        }
        let pitch = tuning[activeString].pitch;
        // console.log('playing... ' + pitch)
        let pitchFile = "snd/" + pitch + ".[ogg|mp3]";
        playUrl(pitchFile)

        setAriaMessage("playing note " + tuning[activeString].aria)
    }
    const playUrl = (url) => {
        let distWetLevel = props.wetLevel;
        if (!props.distOn) {
            distWetLevel = 0;
        }
        dist.wet.value = distWetLevel
        player.current = new Tone.Player({url: url, volume: props.volume}).chain(dist).toMaster()
        player.current.autostart = true;
        player.current.loop = true;
    }

    const setStringVibrations = (id) => {
        let strClassArr = ['', '', '', '', '', '']
        strClassArr[id] = 'vibrate'
        return strClassArr
    }

    const stopNote = () => {
        player.current.stop()
        activateString(null)
    }

    const handleSpaceKey = () => {
        // console.log('handleSpaceKey')
        if (activeString === null) {
            // console.log('start notes')
            activateString(0)
        } else if (activeString >= Object.keys(tuning).length - 1) {
            // console.log('stop notes')
            activateString(null)
        } else {
            // console.log('next note')
            let nextString = activeString + 1
            activateString(nextString)
        }
    }
    const handle4Key = () => {
        setTuning(data[0]['tuning'])
    }
    const handle5Key = () => {
        setTuning(data[1]['tuning'])
    }
    const handle6Key = () => {
        setTuning(data[3]['tuning'])
    }
    const handleSKey = () => {
        // props.setShowKeyShortcuts(false)
        stopNote()
    }
    const handleBKey = () => {
        // props.setShowKeyShortcuts(true)
        stopNote()
        playUrl("snd/B0.[ogg|mp3]")
    }
    const handleEKey = () => {
        // props.setShowKeyShortcuts(true)
        stopNote()
        playUrl("snd/E1.[ogg|mp3]")
    }
    const handleAKey = () => {
        stopNote()
        playUrl("snd/A1.[ogg|mp3]")
        // props.setShowKeyShortcuts(true)
    }
    const handleDKey = () => {
        // props.setShowKeyShortcuts(true)
        stopNote()
        playUrl("snd/D2.[ogg|mp3]")
    }
    const handleGKey = () => {
        // props.setShowKeyShortcuts(true)
        stopNote()
        playUrl("snd/G2.[ogg|mp3]")
    }

    return (
        <div className="scaling-svg-container" style={{'paddingBottom': props.svgPaddingBottom}}>
            <AriaLive message={ariaMessage}/>
            <svg className="scaling-svg" style={{'height': props.scalingSVGH, 'width':props.scalingSVGW}} viewBox={"0 0 " + props.vpw + " " + props.vph}>
                <HotKey
                    handleSpace={handleSpaceKey.bind(this)}
                    handle4Key={handle4Key.bind(this)}
                    handle5Key={handle5Key.bind(this)}
                    handle6Key={handle6Key.bind(this)}
                    handleSKey={handleSKey.bind(this)}
                    handleBKey={handleBKey.bind(this)}
                    handleEKey={handleEKey.bind(this)}
                    handleAKey={handleAKey.bind(this)}
                    handleDKey={handleDKey.bind(this)}
                    handleGKey={handleGKey.bind(this)}
                />
                <g id="nut">
                    <g>
                        <g>
                            <defs>
                                <rect id="SVGID_1_" x="69" y="1.5" width="250.229" height="800"/>
                            </defs>
                            <clipPath id="SVGID_2_">
                                <use href="#SVGID_1_" overflow="visible"/>
                            </clipPath>
                            <path clipPath="url(#SVGID_2_)" stroke="#000000" strokeWidth="17" strokeMiterlimit="10" d="M50.28-2098.025L26.105,2902.147
				C206.639,2059.1,300.604,1184.12,296.311,286.53C292.404-530.084,207.471-1327.265,50.28-2098.025z"/>
                        </g>
                        <path fill="none" stroke="#6D6E71" strokeWidth="6" strokeMiterlimit="10"
                              d="M298.031,0.5c2.613,0,4.371,177.839,4.371,442.009"/>
                    </g>
                </g>
                <g id="neck">
                    <g>
                        <g>
                            <defs>
                                <rect id="SVGID_3_" y="0.5" width="250.228" height="800"/>
                            </defs>
                            <clipPath id="SVGID_4_">
                                <use href="#SVGID_3_" overflow="visible"/>
                            </clipPath>
                            <path clipPath="url(#SVGID_4_)" fill="#3C2415" stroke="#BCBEC0" strokeWidth="17"
                                  strokeMiterlimit="10" d="M-18.72-2099.025
				l-24.174,5000.173C137.639,2058.1,231.604,1183.12,227.311,285.53C223.405-531.084,138.47-1328.265-18.72-2099.025z"/>
                        </g>
                        <path fill="none" stroke="#E6E7E8" strokeWidth="6" strokeMiterlimit="10" d="M229.031-0.5c2.613,0,4.372,177.839,4.372,442.009
			"/>
                        <line fill="none" stroke="#A7A9AC" strokeWidth="3" strokeMiterlimit="10" x1="231.395" y1="637.6"
                              x2="225.395" y2="800.5"/>
                    </g>
                </g>
                <g id="strings">
                    <g id="string0Group">
                        <g id="string0" className={"stringSVG " + strClass[0]}>
                            <radialGradient id="SVGID_5_" cx="3" cy="364.5" r="60"
                                            gradientTransform="matrix(0 1 -1 0 687.5 87.5)"
                                            gradientUnits="userSpaceOnUse">
                                <stop offset="0" style={{'stopColor': STYLE.stopColor1}}/>
                                <stop offset="1" style={{'stopColor': STYLE.stopColor2}}/>
                            </radialGradient>
                            <circle id="str0Circle" fill="url(#SVGID_5_)" stroke="#808285" strokeMiterlimit="10"
                                    cx="323"
                                    cy="90.5" r="60"/>
                            <path fill="none" stroke="#E6E7E8" strokeMiterlimit="10"
                                  d="M323,30.5c33.164,0,60,26.836,60,60"/>
                            <path fill="none" stroke="#6D6E71" strokeMiterlimit="10"
                                  d="M263,90.5c0,33.164,26.836,60,60,60"/>
                        </g>
                        <g>
                            <text x="435" y="90.5" textAnchor="middle"
                                  className="noteText">{activeString === 0 ? '\u2190' : ''}</text>
                            <text x="500" y="90.5" textAnchor="middle"
                                  className="noteText">{tuning[0].name}</text>
                        </g>
                        <rect id="string0Group-clickTarget" className="stringSVGClickTarget" role="button"
                              onClick={() => activateString(0)} x="250" y="40" width="350" height="100" opacity="0"/>
                    </g>
                    <g id="string1Group">
                        <g id="string1" className={"stringSVG " + strClass[1]}>
                            <radialGradient id="SVGID_6_" cx="172.04" cy="367.6709" r="52.1291"
                                            gradientTransform="matrix(0 0.9592 -0.9592 0 672.6539 91.8868)"
                                            gradientUnits="userSpaceOnUse">
                                <stop offset="0" style={{'stopColor': STYLE.stopColor1}}/>
                                <stop offset="1" style={{'stopColor': STYLE.stopColor2}}/>
                            </radialGradient>
                            <circle fill="url(#SVGID_6_)" stroke="#808285" strokeMiterlimit="10" cx="320" cy="256.9"
                                    r="50"/>
                            <path fill="none" stroke="#E6E7E8" strokeMiterlimit="10"
                                  d="M320,206.899c27.637,0,50,20.172,50,45.101"/>
                            <path fill="none" stroke="#6D6E71" strokeMiterlimit="10"
                                  d="M270,256.9c0,27.637,22.363,50,50,50"/>
                        </g>
                        <g>
                            <text x="435" y="256.9" textAnchor="middle"
                                  className="noteText">{activeString === 1 ? '\u2190' : ''}</text>
                            <text x="500" y="256.9" textAnchor="middle"
                                  className="noteText">{tuning[1].name}</text>
                        </g>
                        <rect id="string1Group-clickTarget" className="stringSVGClickTarget" role="button"
                              onClick={() => activateString(1)} x="250" y="210" width="350" height="100" opacity="0"/>
                    </g>
                    <g id="string2Group">
                        <g id="string2" className={"stringSVG " + strClass[2]}>
                            <radialGradient id="SVGID_7_" cx="324.7998" cy="369.5" r="40"
                                            gradientTransform="matrix(0 1 -1 0 687.5 87.5)"
                                            gradientUnits="userSpaceOnUse">
                                <stop offset="0" style={{'stopColor': STYLE.stopColor1}}/>
                                <stop offset="1" style={{'stopColor': STYLE.stopColor2}}/>
                            </radialGradient>
                            <circle fill="url(#SVGID_7_)" stroke="#808285" strokeMiterlimit="10" cx="318" cy="412.3"
                                    r="40"/>
                            <path fill="none" stroke="#E6E7E8" strokeMiterlimit="10"
                                  d="M318,372.3c22.109,0,40,17.891,40,40"/>
                            <path fill="none" stroke="#6D6E71" strokeMiterlimit="10"
                                  d="M278,412.3c0,22.109,17.891,40,40,40"/>
                        </g>
                        <g>
                            <text x="435" y="412.3" textAnchor="middle"
                                  className="noteText">{activeString === 2 ? '\u2190' : ''}</text>
                            <text x="500" y="412.3" textAnchor="middle"
                                  className="noteText">{tuning[2].name}</text>
                        </g>
                        <rect id="string2Group-clickTarget" className="stringSVGClickTarget" role="button"
                              onClick={() => activateString(2)} x="250" y="363" width="350" height="100" opacity="0"/>
                    </g>
                    <g id="string3Group">
                        <g id="string3" className={"stringSVG " + strClass[3]}>
                            <radialGradient id="SVGID_8_" cx="454.2002" cy="375.5" r="30"
                                            gradientTransform="matrix(0 1 -1 0 687.5 87.5)"
                                            gradientUnits="userSpaceOnUse">
                                <stop offset="0" style={{'stopColor': STYLE.stopColor1}}/>
                                <stop offset="1" style={{'stopColor': STYLE.stopColor2}}/>
                            </radialGradient>
                            <circle fill="url(#SVGID_8_)" stroke="#808285" strokeMiterlimit="10" cx="312" cy="541.7"
                                    r="30"/>
                            <path fill="none" stroke="#E6E7E8" strokeMiterlimit="10"
                                  d="M312,511.7c16.582,0,30,13.418,30,30"/>
                            <path fill="none" stroke="#6D6E71" strokeMiterlimit="10"
                                  d="M282,541.7c0,16.582,13.418,30,30,30"/>
                        </g>
                        <g>
                            <text x="435" y="541.7" textAnchor="middle"
                                  className="noteText">{activeString === 3 ? '\u2190' : ''}</text>
                            <text x="500" y="541.7" textAnchor="middle"
                                  className="noteText">{tuning[3].name}</text>
                        </g>
                        <rect id="string3Group-clickTarget" className="stringSVGClickTarget" role="button"
                              onClick={() => activateString(3)} x="250" y="493" width="350" height="100" opacity="0"/>
                    </g>
                    <g id="string4Group">
                        <g id="string4" className={"stringSVG " + strClass[4]}>
                            <radialGradient id="SVGID_9_" cx="569.5996" cy="382.5" r="20"
                                            gradientTransform="matrix(0 1 -1 0 687.5 87.5)"
                                            gradientUnits="userSpaceOnUse">
                                <stop offset="0" style={{'stopColor': STYLE.stopColor1}}/>
                                <stop offset="1" style={{'stopColor': STYLE.stopColor2}}/>
                            </radialGradient>
                            <circle fill="url(#SVGID_9_)" stroke="#808285" strokeMiterlimit="10" cx="305" cy="657.1"
                                    r="20"/>
                            <path fill="none" stroke="#E6E7E8" strokeMiterlimit="10"
                                  d="M305,637.1c11.055,0,20,8.945,20,20"/>
                            <path fill="none" stroke="#6D6E71" strokeMiterlimit="10"
                                  d="M285,657.1c0,11.055,8.945,20,20,20"/>
                        </g>
                        <g>
                            <text x="435" y="657.1" textAnchor="middle"
                                  className="noteText">{activeString === 4 ? '\u2190' : ''}</text>
                            <text x="500" y="657.1" textAnchor="middle"
                                  className="noteText">{tuning.length > 4 ? tuning[4].name : ''}</text>
                        </g>
                        <rect id="string4Group-clickTarget" className="stringSVGClickTarget" role="button"
                              onClick={() => activateString(4)} x="250" y="607" width="350" height="100" opacity="0"/>
                    </g>
                    <g id="string5Group">
                        <g id="string5" className={"stringSVG " + strClass[5]}>
                            <radialGradient id="SVGID_10_" cx="671" cy="386.5" r="14"
                                            gradientTransform="matrix(0 1 -1 0 687.5 87.5)"
                                            gradientUnits="userSpaceOnUse">
                                <stop offset="0" style={{'stopColor': STYLE.stopColor1}}/>
                                <stop offset="1" style={{'stopColor': STYLE.stopColor2}}/>
                            </radialGradient>
                            <circle fill="url(#SVGID_10_)" stroke="#808285" strokeMiterlimit="10" cx="301" cy="758.5"
                                    r="14"/>
                            <path fill="none" stroke="#E6E7E8" strokeMiterlimit="10"
                                  d="M301,744.5c7.738,0,14,6.262,14,14"/>
                            <path fill="none" stroke="#6D6E71" strokeMiterlimit="10"
                                  d="M287,758.5c0,7.738,6.262,14,14,14"/>
                        </g>
                        <g>
                            <text x="435" y="758.5" textAnchor="middle"
                                  className="noteText">{activeString === 5 ? '\u2190' : ''}</text>
                            <text x="500" y="758.5" textAnchor="middle"
                                  className="noteText">{tuning.length > 5 ? tuning[5].name : ''}</text>
                        </g>
                    </g>
                    <rect id="string5Group-clickTarget" className="stringSVGClickTarget" role="button"
                          onClick={() => activateString(5)} x="250" y="711" width="350" height="100" opacity="0"/>
                </g>

                {/*<String />*/}
            </svg>
        </div>
    )

}

export default Tuner