import {createMuiTheme} from "@material-ui/core"

const defaultTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#000',
            light: '#ffd449',
            dark: '#c67400',
            contrastText: '#FFF',
        },
        // secondary: {
        //     main: '#ff8500',
        //     light: '#ffb644',
        //     dark: '#c55600',
        //     contrastText: '#000000',
        // },
        secondary: {
            main: '#F79421',
            light: '#d1d1d1',
            dark: '#242424',
            contrastText: '#000000',
        },
    },
})

// eslint-disable-next-line
const {breakpoints, typography: {pxToRem}} = defaultTheme

// set theme to defaultTheme and add responsive text sizing to Typography
const theme = {
    ...defaultTheme,
    overrides: {
        MuiTypography: {
            gutterBottom: { marginBottom: "1em" },
            body1: {
                marginBottom: "1em",
                fontSize: "1.5em",
                [breakpoints.down("md")]: {
                    fontSize: "1.25em"
                },
                [breakpoints.down("xs")]: {
                    fontSize: "1em"
                }
            },
            body2: {
                marginBottom: "1em",
                fontSize: "1.2em",
                [breakpoints.down("md")]: {
                    fontSize: "1.1em"
                },
                [breakpoints.down("xs")]: {
                    fontSize: "1em"
                }
            },
            h1: {
                fontWeight: "bold",
                fontSize: "3em",
                [breakpoints.down("md")]: {
                    fontSize: "2em"
                },
                [breakpoints.down("xs")]: {
                    fontSize: "1.5em"
                }
            },
            h3: {
                fontWeight: "bold",
                fontSize: "2em",
                [breakpoints.down("md")]: {
                    fontSize: "1.5em"
                },
                [breakpoints.down("xs")]: {
                    fontSize: "1.25em"
                }
            }
        }
    }
}

export default theme