import React from 'react';
import {AppBar, Toolbar, Button, Hidden, Drawer, ListItem, ListItemText, ListItemIcon} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import {withStyles} from '@material-ui/core/styles';
import {Link as RouterLink} from 'react-router-dom';
import MenuIcon from "@material-ui/icons/Menu";


const styles = theme => ({
    appBar: {
        backgroundColor: '#000'
    },
    hamburger: {
        background: theme.background,
        color: 'white',
        padding: '0'
    },
    drawer: {
        root: {
            backgroundColor: '#000'
        }
    },
    menuButton: {
        color: '#FB2345'
    },
    root: {
        background: theme.background,
        color: 'white',
        padding: '0 30px',
        "&:hover": {
            color: "black",
            background: "#F79421"
        }
    },
});


const TMBHeader = (props) => {
    const {classes} = props;
    const [state, setState] = React.useState({
        top: false
    });
    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({...state, [side]: open});
    };

    return (
        <header className={classes.header}>
            <AppBar position="static" elevation={0} classes={{root: classes.appBar}}>
                <Toolbar>

                    <Hidden mdUp>
                        <Button classes={{root: classes.hamburger}} onClick={toggleDrawer('top', true)}><MenuIcon/></Button>
                    </Hidden>

                    <div className="logo">
                        <img src="/svg/TMBLogo.svg"
                             style={{"maxHeight": "64px", "float": "left", "marginRight": "12px"}} alt="Tune My Bass"
                             aria-label="Tune My Bass logo"/>
                    </div>

                    <Hidden smDown>
                        <Button classes={{root: classes.root}} component={RouterLink} to="/"
                                aria-label="Main tuning note page">Tune</Button>
                        <Button classes={{root: classes.root}} component={RouterLink} to="/how-to-tune"
                                aria-label="How to tune videos">How to Tune</Button>
                        <Button classes={{root: classes.root}} component={RouterLink} to="/about"
                                aria-label="About the tune my bass dot com website">About</Button>
                        <Button classes={{root: classes.root}} href="https://www.studybass.com/" target="_blank"
                                rel="noopener noreferrer" aria-label="Link to Study Bass dot com">
                            StudyBass.com <OpenInNewIcon fontSize={"small"}/>
                        </Button>
                    </Hidden>
                </Toolbar>
            </AppBar>
            {/* Mobile Menu */}
            <Drawer classes={{root: classes.drawer}} anchor="top" open={state.top} onClose={toggleDrawer('top', false)}>
                <div onKeyDown={toggleDrawer('top', false)}>
                    <ListItem button component={RouterLink} to="/" aria-label="Main tuning note page" onClick={toggleDrawer('top', false)}>
                        <ListItemText primary={'Tune'} />
                    </ListItem>
                    <ListItem button component={RouterLink} to="/how-to-tune" aria-label="How to tune your bass videos" onClick={toggleDrawer('top', false)}>
                        <ListItemText primary={'How to Tune'} />
                    </ListItem>
                    <ListItem button component={RouterLink} to="/about" aria-label="About the tune my bass dot com website" onClick={toggleDrawer('top', false)}>
                        <ListItemText primary={'About'} />
                    </ListItem>
                    <ListItem button href="https://www.studybass.com/" target="_blank"
                              rel="noopener noreferrer" aria-label="Link to Study Bass dot com" onClick={toggleDrawer('top', false)}>
                        <ListItemText primary={'StudyBass.com'}/>
                        <ListItemIcon>
                            <OpenInNewIcon fontSize={"small"}/>
                        </ListItemIcon>
                    </ListItem>

                    {/*<Button onKeyDown={toggleDrawer('top', false)} component={RouterLink} to="/how-to-tune"*/}
                    {/*        aria-label="How to tune videos">How to Tune</Button>*/}
                    {/*<Button onKeyDown={toggleDrawer('top', false)} component={RouterLink} to="/about"*/}
                    {/*        aria-label="About the tune my bass dot com website">About</Button>*/}
                    {/*<Button onKeyDown={toggleDrawer('top', false)} href="https://www.studybass.com/" target="_blank"*/}
                    {/*        rel="noopener noreferrer" aria-label="Link to Study Bass dot com">*/}
                    {/*    StudyBass <LinkIcon/>*/}
                    {/*</Button>*/}
                </div>
            </Drawer>
            {/* END Mobile Menu */}
        </header>
    );

}

// Header.propTypes = {
//     classes: PropTypes.object.isRequired,
// };

export default withStyles(styles)(TMBHeader);