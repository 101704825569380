import React from 'react';
import {Link} from 'react-router-dom';
import {Grid, Typography} from '@material-ui/core';
import {Helmet} from "react-helmet";

function About() {
    return (
        <>
            <Helmet>
                <title>About Tune My Bass</title>
            </Helmet>
            <Grid container justify="center">
                <Grid item xs={12} sm={8} md={6} >
                    <Typography variant={"h1"} align={"center"} gutterBottom>About TuneMyBass</Typography>
                    <Typography variant="body1">TuneMyBass existed prior to my other website <a
                        href="https://www.studybass.com/">StudyBass</a>.
                        Many of the TuneMyBass articles and gear pages have been moved there. I have left this site up for a quick
                        tuning note reference.</Typography>
                    <Typography variant="body1">Once you're done <Link to="/" title="Tune your bass online">tuning your
                        bass</Link> here, I hope you'll learn <a href="https://www.studybass.com/"
                        title="Online Bass Lessons">how to play the bass</a> at StudyBass.</Typography>
                    <Typography variant="body1">If you want to support my efforts, you can <a
                        href="https://www.studybass.com/donate/">donate to StudyBass</a>. Anything helps. Thank you!</Typography>
                    <Typography variant="body1">Good luck with your musical pursuits!</Typography>
                    <Typography variant="body1">Andrew Pouska</Typography>
                </Grid>
            </Grid>
        </>
    );
}

export default About